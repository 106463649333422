// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "../../@cmcore/component/cmform/cmform.component";

import {
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridEditConfig,
  CMInputConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
  CMINPUT_CONTROL_VALUE_ACCESSOR,
} from "../../@cmcore/component";
import {
    PreVendaBrowseComponent, ProdutoBrowseComponent,
} from "../index";
import { FuncsGlobais } from "../funcs.globais";
@Component({
  selector: "prevenda-gerar-saida-estoque",
  templateUrl: "prevenda-gerar-saida-estoque.component.html",
  styleUrls: ["prevenda-gerar-saida-estoque.component.scss"],
})
export class PreVendaGerarSaidaEstoqueComponent extends CMFormComponent {
  
  public filter: any = {};
  private innerFilterConfig = {};
  public get filterConfig() {
    return this.innerFilterConfig;
  }
  public set filterConfig(value: any) {
    this.innerFilterConfig = value;
    this.filter = {};
    this.generateEmptyModel(value, this.filter);
  }
  
  valid() {
    return super.valid(this.filterConfig, this.filter);
	}

  _owner: PreVendaBrowseComponent = undefined;
  
  setFilterConfig(){
    this.filterConfig = {
      
      historico: new CMGridEditConfig({
        caption: "Histórico de Saídas Anteriores",
        form: PreVendaGerarSaidaEstoqueComponent,
        onRemoveItem: (item) => {
          this.postDeleteSaidaEstoque(item);
          console.log(this._owner.grid.fieldByName(this.owner.fieldNameId()));
        },
        fields: {
          produto: new CMInputConfig({
            form: this,
            caption: "Produto",
            disabled: true,
            maxlength: 30,
            padInfo: { size: 30 },
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "produto.codigo");
              },
              getBottomLabelColumn: (row) => {
                return this.getValueObject(row, "produto.descr");
              },
            }),
          }),
          quantidade: new CMInputConfig({
            form: this,
            caption: "Quantidade",
            dataType: "double(2)",
            disabled: true,
            gridConfig: new CMGridEditConfigColumn(),
            hintButton: "Editar",
            iconButton: () => {
              if (this.disabledAll) return "visibility";
              else return "edit";
            },
            showButtonClear: false,
            pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
            pesqFunc: (sender) => {
              if (this.mostraDetalhesQuantidade(this.filterConfig.historico.current)){

                this.postSubscribe(
                  '/api/saidaestoque/GetSaidasEstoqueDetalhe',
                  JSON.stringify({
                    idprevenda: this._owner.grid.fieldByName(this.owner.fieldNameId()),
                    idproduto: this.filterConfig.historico.current.produto.id,
                    datasaida: this.filterConfig.historico.current.datasaida
                  }),
                  (result: any) => {
                    this.filterConfig.historico.current.estoque = result;
          
                    console.log('this.dados.historico: ', this.dados.historico);
                    console.log('result: ', result);
                  },
                  error => {
                    CMFormGlobaisComponent.hideSpinner();
                    this.showMessageErrorHTTP('GetSaidasEstoque', error);
                  }
                )

                this.filterConfig.historico.extraClick(
                  new CMGridEditConfigMore({
                    index: 0,
                    title: "Detalhes da Quantidade",
                  })
                );
              }
              setTimeout(() => {
                document.querySelector("[id^='extra_grid_']").parentNode.lastChild.remove();
              }, 1500)
            },
            showButtonFunc: (show, row) => this.mostraDetalhesQuantidade(row),
          }),
          datasaida: new CMInputConfig({
            form: this,
            disabled: true,
            caption: "Data da Saída",
            gridConfig: new CMGridEditConfigColumn(),
          }),
          select: new CMInputConfig({
            form: this,
            caption: "Selecione para Excluir",
            gridConfig: new CMGridEditConfigColumn({
              tipo: "checkbox"
            }),
          }),
          estoque: new CMGridEditConfig({
            form: this,
            required: false,
            fields: {
              grade: new CMInputConfig({
                form: this,
                caption: "Grade",
                disabled: true,
                pesqOnly: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "grade.descricao");
                  },
                }),
                visibleFunc: () => {
                  return this.currentProdutoUsaGrade();
                },
              }),
              numerolote: new CMInputConfig({
                form: this,
                caption: "Número Lote",
                dataType: "string",
                maxlength: 20,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  disabledFunc: (row) => {
                    if (this.currentProdutoUsaGrade())
                      return this.getValueObject(row, "grade.id") === "";
                    else return false;
                  },
                  getValue: (row) => {
                    return this.getValueObject(row, "numerolote");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisaLote(
                    sender,
                    this.filterConfig.historico.fields.estoque.current,
                    this.filterConfig.historico.current.produto.id,
                    this.filterConfig.historico.fields.estoque.current.grade.id
                  );
                },
                requiredFunc: () => {
                  return this.currentProdutoUsaLote();
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaLote();
                },
              }),
              datavalidadelote: new CMInputConfig({
                form: this,
                caption: "Data Validade Lote",
                mask: "99/99/9999",
                disabled: true,
                gridConfig: new CMGridEditConfigColumn(),
                requiredFunc: () => {
                  return this.currentProdutoUsaValidadeLote();
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaValidadeLote();
                },
              }),
              quantidade: new CMInputConfig({
                form: this,
                disabled: true,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn(),
                disabledFunc: () => {
                  return this.date !== this.filterConfig.historico.current.datasaida;
                },
                /*
                onKeyPress: (row) => {
                  console.log('changed: ');
                  let item = this.filterConfig.itens.current;

                  item.estoquesaida.forEach((est) => {
                    est.quantidade = 0;
                  });
                  item.quantidadesaida = 0;
                },*/
              }),
            },
          }),
        }
      }),
      itens: new CMGridEditConfig({
        caption: "Saldo de Produto à Entregar",
        form: PreVendaGerarSaidaEstoqueComponent,
        fields: {
          produto: new CMInputConfig({
            form: this,
            caption: "Produto",
            disabled: true,
            maxlength: 30,
            padInfo: { size: 30 },
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "produto.codigo");
              },
              getBottomLabelColumn: (row) => {
                return this.getValueObject(row, "produto.descr");
              },
            }),
          }),
          quantidade: new CMInputConfig({
            form: this,
            caption: "Quantidade",
            dataType: "double(2)",
            disabled: true,
            gridConfig: new CMGridEditConfigColumn(),
            hintButton: "Editar",
            iconButton: () => {
              if (this.disabledAll) return "visibility";
              else return "edit";
            },
            showButtonClear: false,
            pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
            pesqFunc: (sender) => {
              if (this.mostraDetalhesQuantidade(this.filterConfig.itens.current))
                this.filterConfig.itens.extraClick(
                  new CMGridEditConfigMore({
                    index: 0,
                    title: "Detalhes da Quantidade",
                  })
                );
              setTimeout(() => {
                document.querySelector("[id^='extra_grid_']").parentNode.lastChild.remove();
              }, 1500)
            },
            showButtonFunc: (show, row) => this.mostraDetalhesQuantidade(row),
          }),
          quantidadeentregue: new CMInputConfig({
            form: this,
            caption: "Quantidade Entregue",
            dataType: "double(2)",
            disabled: true,
            gridConfig: new CMGridEditConfigColumn(),
            hintButton: "Editar",
            iconButton: () => {
              if (this.disabledAll) return "visibility";
              else return "edit";
            },
            showButtonClear: false,
            pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
            pesqFunc: (sender) => {
              if (this.mostraDetalhesQuantidade(this.filterConfig.itens.current)){
                this.filterConfig.itens.extraClick(
                  new CMGridEditConfigMore({
                    index: 2,
                    title: "Detalhes da Quantidade",
                  })
                );
                setTimeout(() => {
                  document.querySelector("[id^='extra_grid_']").parentNode.lastChild.remove();
                }, 1500)
              }
            },
            showButtonFunc: (show, row) => this.mostraDetalhesQuantidade(row),
          }),
          /*
          datasaida: new CMInputConfig({
            form: this,
            disabled: true,
            caption: "Data da Saída",
            gridConfig: new CMGridEditConfigColumn(),
          }),*/
          quantidadesaida: new CMInputConfig({
            form: this,
            caption: "Quantidade Saída",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn(),
            hintButton: "Editar",
            iconButton: () => {
              if (this.disabledAll) return "visibility";
              else return "edit";
            },
            showButtonClear: false,
            pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
            pesqFunc: (sender) => {
              if (this.mostraDetalhesQuantidade(this.filterConfig.itens.current)){
                this.filterConfig.itens.extraClick(
                  new CMGridEditConfigMore({
                    index: 1,
                    title: "Detalhes da Quantidade",
                  })
                );
                setTimeout(() => {
                  document.querySelector("[id^='extra_grid_']").parentNode.lastChild.remove();
                }, 1500)
              }
            },
            showButtonFunc: (show, row) => this.mostraDetalhesQuantidade(row),
            onExit: () => {
              if (!this.currentProdutoUsaGrade() && !this.currentProdutoUsaLote()) {
                this.filterConfig.itens.current.estoquesaida[0].quantidade = this.filterConfig.itens.current.quantidadesaida;
              }
            }
          }),
          estoque: new CMGridEditConfig({
            form: this,
            required: false,
            fields: {
              grade: new CMInputConfig({
                form: this,
                caption: "Grade",
                disabled: true,
                pesqOnly: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "grade.descricao");
                  },
                }),
                visibleFunc: () => {
                  return this.currentProdutoUsaGrade();
                },
              }),
              numerolote: new CMInputConfig({
                form: this,
                caption: "Número Lote",
                dataType: "string",
                maxlength: 20,
                disabled: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  disabledFunc: (row) => {
                    if (this.currentProdutoUsaGrade())
                      return this.getValueObject(row, "grade.id") === "";
                    else return false;
                  },
                  getValue: (row) => {
                    return this.getValueObject(row, "numerolote");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisaLote(
                    sender,
                    this.filterConfig.itens.fields.estoque.current,
                    this.filterConfig.itens.current.produto.id,
                    this.filterConfig.itens.fields.estoque.current.grade.id
                  );
                },
                requiredFunc: () => {
                  return this.currentProdutoUsaLote();
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaLote();
                },
              }),
              datavalidadelote: new CMInputConfig({
                form: this,
                caption: "Data Validade Lote",
                mask: "99/99/9999",
                disabled: true,
                gridConfig: new CMGridEditConfigColumn(),
                requiredFunc: () => {
                  return this.currentProdutoUsaValidadeLote();
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaValidadeLote();
                },
              }),
              quantidade: new CMInputConfig({
                form: this,
                disabled: true,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn(),
              }),
            },
          }),
          estoquesaida: new CMGridEditConfig({
            form: this,
            required: false,
            fields: {
              grade: new CMInputConfig({
                form: this,
                caption: "Grade",
                disabled: true,
                pesqOnly: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "grade.descricao");
                  },
                }),
                visibleFunc: () => {
                  return this.currentProdutoUsaGrade();
                },
              }),
              numerolote: new CMInputConfig({
                form: this,
                caption: "Número Lote",
                dataType: "string",
                maxlength: 20,
                disabled: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  disabledFunc: (row) => {
                    if (this.currentProdutoUsaGrade())
                      return this.getValueObject(row, "grade.id") === "";
                    else return false;
                  },
                  getValue: (row) => {
                    return this.getValueObject(row, "numerolote");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisaLote(
                    sender,
                    this.filterConfig.itens.fields.estoquesaida.current,
                    this.filterConfig.itens.current.produto.id,
                    this.filterConfig.itens.fields.estoquesaida.current.grade.id
                  );
                },
                requiredFunc: () => {
                  return this.currentProdutoUsaLote();
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaLote();
                },
              }),
              datavalidadelote: new CMInputConfig({
                form: this,
                caption: "Data Validade Lote",
                mask: "99/99/9999",
                disabled: true,
                gridConfig: new CMGridEditConfigColumn(),
                requiredFunc: () => {
                  return this.currentProdutoUsaValidadeLote();
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaValidadeLote();
                },
              }),
              quantidade: new CMInputConfig({
                form: this,
                disabled: false,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn(),
                onExit: () => {
                  this.calcTotalItemEstoque();
                },
              }),
            },
          }),
          estoqueentregue: new CMGridEditConfig({
            form: this,
            required: false,
            onGetValue: () => {
              console.log("onGetValue");
            },
            fields: {
              grade: new CMInputConfig({
                form: this,
                caption: "Grade",
                pesqOnly: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "grade.descricao");
                  },
                }),
                visibleFunc: () => {
                  return this.currentProdutoUsaGrade();
                }
              }),
              numerolote: new CMInputConfig({
                form: this,
                caption: "Número Lote",
                dataType: "string",
                maxlength: 20,
                disabled: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  disabledFunc: (row) => {
                    if (this.currentProdutoUsaGrade())
                      return this.getValueObject(row, "grade.id") === "";
                    else return false;
                  },
                  getValue: (row) => {
                    return this.getValueObject(row, "numerolote");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisaLote(
                    sender,
                    this.filterConfig.itens.fields.estoqueentregue.current,
                    this.filterConfig.itens.current.produto.id,
                    this.filterConfig.itens.fields.estoqueentregue.current.grade.id
                  );
                },
                requiredFunc: () => {
                  return this.currentProdutoUsaLote();
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaLote();
                },
              }),
              datavalidadelote: new CMInputConfig({
                form: this,
                caption: "Data Validade Lote",
                mask: "99/99/9999",
                disabled: true,
                gridConfig: new CMGridEditConfigColumn(),
                requiredFunc: () => {
                  return this.currentProdutoUsaValidadeLote();
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaValidadeLote();
                },
              }),
              quantidadeentregue: new CMInputConfig({
                form: this,
                disabled: true,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn(),
                /*
                onKeyPress: (row) => {
                  console.log('changed: ');
                  let item = this.filterConfig.itens.current;

                  item.estoquesaida.forEach((est) => {
                    est.quantidade = 0;
                  });
                  item.quantidadesaida = 0;
                },
                disabledFunc: () => {
                  return this.filterConfig.itens.current.datasaida !== this.filterConfig.historico.current.datasaida;
                }*/
              }),
            },
          }),
        }
      })
    }
  }


  postDeleteSaidaEstoque(item: any) {
    console.log("item a ser excluído: ", item);
    
    //let itemsaidaX = this.dados.produtos.find(itemprevenda => itemprevenda.id === itemsaida.id);
    //itemsaidaX = null;
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/saidaestoque/DeleteSaidaEstoque",
      JSON.stringify({
        idprevenda: this._owner.grid.fieldByName(this.owner.fieldNameId()),
        data: item.datasaida,
        idproduto: item.produto.id
      }),
      (res) => {
        if (res["result"] == "ok") {

          let itemsaida = this.dados.produtos.find(itemprevenda => itemprevenda.id === item.id);
      
          let qtdtemp = itemsaida.quantidadeentregue;
      
          itemsaida.quantidadeentregue = itemsaida.quantidadesaida;
          itemsaida.quantidadesaida = qtdtemp;
      
          let estoquetemp = itemsaida.estoqueentregue;
      
          itemsaida.estoqueentregue = itemsaida.estoquesaida;
          itemsaida.estoquesaida = estoquetemp;
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        this.showMessageErrorHTTP("postDeleteAnotacao", error);
        CMFormGlobaisComponent.hideSpinner();
      }
    );
  }
  

  currentProdutoUsaValidadeLote() {
    return (
      this.getValueObject(
        this.filterConfig.itens.current,
        "produto.usavalidadelote"
      ) === "Sim")
  }

  dados = {
    produtos: [],
    historico: [],
  };
  
  currentProdutoUsaLote() {
    return (
      this.getValueObject(this.filterConfig.itens.current, "produto.usalote") ===
      "Sim"
    );
  }

  calcTotalItemEstoque() {

    let item = this.filterConfig.itens.current;
    
    let qtotal = 0;

    item.estoquesaida.forEach((est) => {
      qtotal += this.valueToFloat(est.quantidade);
    });
    item.quantidadesaida = this.floatToValue(qtotal);
  }

  currentProdutoUsaGrade() {
    return (
      this.getValueObject(
        this.filterConfig.itens.current,
        "produto.usagrade1"
      ) === "Sim" ||
      this.getValueObject(
        this.filterConfig.itens.current,
        "produto.usagrade2"
      ) === "Sim"
    );
  }

  mostraDetalhesQuantidade(row) {
    let r = false;
    if (row && row.produto) {
      r =
        row.produto.usagrade1 === "Sim" ||
        row.produto.usagrade2 === "Sim" ||
        row.produto.usalote   === "Sim";
    }
    return r;
  }

  get owner() {
    return this._owner;
  }

  set owner(value) {
    this._owner = value;
    if (this._owner !== undefined) {

      this.getObjectData('prevenda',
      [
        {'field': 'empresa', 'value': this.paramEmpresa.idEmpresa},
        {'field': 'id', 'value': this._owner.grid.fieldByName(this.owner.fieldNameId())},
      ],
      object => {
        
        console.log('Object: ', object);

        object.itens.forEach((item) => {
          item.datasaida = CMFormComponent.date();
          item.estoquesaida = $.extend( true, [], item.estoque );
          item.estoqueentregue = item.estoque;

          let qtotal = 0;
          item.estoquesaida.forEach((est) => {
            qtotal += this.valueToFloat(est.quantidadeentregue);
            //console.log('est.quantidadesaida: ', est.quantidade);
            est.quantidade = this.floatToValue(this.valueToFloat(est.quantidade) - this.valueToFloat(est.quantidadeentregue));
          });

          item.quantidadesaida = this.floatToValue(this.valueToFloat(item.quantidade)-this.valueToFloat(qtotal));
          //console.log('item.quantidadesaida: ', this.valueToFloat(item.quantidade)-this.valueToFloat(qtotal));
          
          item.quantidadeentregue = this.floatToValue(qtotal);

        });

				this.dados.produtos = object.itens;
        //this.dados.historico = object.itens;

        console.log('dadosprodutos: ', this.dados.produtos);

      });

      this.postSubscribe(
        '/api/saidaestoque/GetSaidasEstoque',
        JSON.stringify({idprevenda: this.valueToInteger(this._owner.grid.fieldByName(this.owner.fieldNameId()))}),
        (result: any) => {
          this.dados.historico = result;

          console.log('this.dados.historico: ', this.dados.historico);
          console.log('result: ', result);
        },
        error => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP('GetSaidasEstoque', error);
        }
      )

    }
  }
  
  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  constructor(_injector: Injector) {
    super(_injector);

    this.setFilterConfig();
  }

  close(formName) {
    if (this.owner) this.owner.beforeComponent = undefined;
    CMFormModalComponent.close(formName);
  }


  produtoUsaGrade(item) {
    return (
      this.getValueObject(
        item,
        "produto.usagrade1"
      ) === "Sim" ||
      this.getValueObject(
        item,
        "produto.usagrade2"
      ) === "Sim"
    );
  }

  produtoUsaLote(item) {
    return (
      this.getValueObject(
        item,
        "produto.usalote"
      ) === "Sim"
    );
  }

  gerarSaidaEstoque() {
    
    let prevendaitemestoque = [];
    let itemscomerro = [];
    let estoquebaixado = true;
    let idprevenda = 0;

    console.log('post dados.produtos: ', this.dados.produtos);

    this.dados.produtos.forEach((item) => {

      CMFormGlobaisComponent.showSpinner();
      for (let i=0; i < item.estoquesaida.length; i++) {

        console.log('item.estoquesaida[i]: ', item.estoquesaida[i]);
        console.log('item.quantidaderestante: ', this.valueToFloat(item.estoque[i].quantidade) - this.valueToFloat(item.estoque[i].quantidadeentregue));

        if (estoquebaixado && (this.valueToFloat(item.estoquesaida[i].quantidade) 
            !== this.valueToFloat(item.estoque[i].quantidade) - this.valueToFloat(item.estoque[i].quantidadeentregue))){
          estoquebaixado = false;
        }

        item.estoquesaida[i].datasaida = item.datasaida;

        if ((this.valueToFloat(item.estoquesaida[i].quantidade) > 0 ) && !this.produtoUsaGrade(item) && !this.produtoUsaLote(item)) {
          item.estoquesaida[i].quantidade = item.quantidadesaida;
          prevendaitemestoque.push(item.estoquesaida[i]);
        }
        else if ((this.valueToFloat(item.estoquesaida[i].quantidade) > 0 ) && this.produtoUsaGrade(item) ){
          prevendaitemestoque.push(item.estoquesaida[i]);
        }
        else if ((this.valueToFloat(item.estoquesaida[i].quantidade) > 0 ) && this.produtoUsaLote(item) && !this.produtoUsaGrade(item) ){
          prevendaitemestoque.push(item.estoquesaida[i]);
        }
      }

      console.log('estoquebaixado: ', estoquebaixado);

      idprevenda = this.valueToInteger(this._owner.grid.fieldByName(this.owner.fieldNameId()));

      if (this.produtoUsaGrade(item)){

        for (let i=0; i < item.estoque.length-1; i++) {

          console.log('item.estoque: ', this.valueToFloat(item.estoque[i].quantidade));
          console.log('item.estoquesaida: ', this.valueToFloat(item.estoquesaida[i].quantidade));
          
          if (this.valueToFloat(item.estoquesaida[i].quantidade) > this.valueToFloat(item.estoque[i].quantidade) - this.valueToFloat(item.estoque[i].quantidadeentregue)){

            if (item.produto.usagrade1 === 'Sim' && item.produto.usagrade2 === 'Não'){
              itemscomerro.push(
                {
                  codigo: this.trimValue(item.produto.codigo), 
                  descricao: item.produto.descr,
                  grade1: item.estoque[i].grade.grade1.codigo,
                  grade2: '',
                  numerolote: '',
                  msgerro: 'A quantidade de saída do produto excede a quantidade restante à ser entregue. <br><strong>Quantidade Restante: </strong>'+
                  this.floatToValue(this.valueToFloat(item.estoque[i].quantidade) - this.valueToFloat(item.estoque[i].quantidadeentregue))+'<br>'+
                  '<strong>Quantidade informada: </strong>'+item.estoquesaida[i].quantidade
                });
            }
            else if (item.produto.usagrade1 === 'Não' && item.produto.usagrade2 === 'Sim'){
              itemscomerro.push(
                {
                  codigo: this.trimValue(item.produto.codigo), 
                  descricao: item.produto.descr,
                  grade1: '',
                  grade2: item.estoque[i].grade.grade2.codigo,
                  numerolote: '' ,
                  msgerro: 'A quantidade de saída do produto excede a quantidade restante à ser entregue. <br><strong>Quantidade Restante: </strong>'+
                  this.floatToValue(this.valueToFloat(item.estoque[i].quantidade) - this.valueToFloat(item.estoque[i].quantidadeentregue))+'<br>'+
                  '<strong>Quantidade informada: </strong>'+item.estoquesaida[i].quantidade
                });
            }
            else if (item.produto.usagrade1 === 'Sim' && item.produto.usagrade2 === 'Sim'){
              itemscomerro.push(
                {
                  codigo: this.trimValue(item.produto.codigo), 
                  descricao: item.produto.descr,
                  grade1: item.estoque[i].grade.grade1.codigo,
                  grade2: item.estoque[i].grade.grade2.codigo,
                  numerolote: '' ,
                  msgerro: 'A quantidade de saída do produto excede a quantidade restante à ser entregue. <br><strong>Quantidade Restante: </strong>'+
                  this.floatToValue(this.valueToFloat(item.estoque[i].quantidade) - this.valueToFloat(item.estoque[i].quantidadeentregue))+'<br>'+
                  '<strong>Quantidade informada: </strong>'+item.estoquesaida[i].quantidade
                });
            }

          }
          else if (this.valueToFloat(item.estoquesaida[i].quantidade) > this.valueToFloat(item.estoque[i].quantidade)) {

            console.log('item: ', item);

            if (item.produto.usagrade1 === 'Sim' && item.produto.usagrade2 === 'Não'){
              itemscomerro.push(
                {
                  codigo: this.trimValue(item.produto.codigo),
                  descricao: item.produto.descr,
                  grade1: item.estoque[i].grade.grade1.codigo,
                  grade2: '',
                  numerolote: '',
                  msgerro: 'A quantidade de saída de produto excede a quantidade do produto da pré-venda.'
                });
            }
            else if (item.produto.usagrade1 === 'Não' && item.produto.usagrade2 === 'Sim'){
              itemscomerro.push(
                {
                  codigo: this.trimValue(item.produto.codigo), 
                  descricao: item.produto.descr,
                  grade1: '',
                  grade2: item.estoque[i].grade.grade2.codigo,
                  numerolote: '' ,
                  msgerro: 'A quantidade de saída de produto excede a quantidade do produto da pré-venda.'
                });
            }
            else if (item.produto.usagrade1 === 'Sim' && item.produto.usagrade2 === 'Sim'){
              itemscomerro.push(
                {
                  codigo: this.trimValue(item.produto.codigo), 
                  descricao: item.produto.descr,
                  grade1: item.estoque[i].grade.grade1.codigo,
                  grade2: item.estoque[i].grade.grade2.codigo,
                  numerolote: '' ,
                  msgerro: 'A quantidade de saída de produto excede a quantidade do produto da pré-venda.'
                });
            }

          }
        }
        

      }
      else{

        let qtotal = 0;
        item.estoquesaida.forEach((est) => {
          qtotal += this.valueToFloat(est.quantidadeentregue);
        });

        //console.log('item: ', item);
        //console.log('item.quantidadesaida: ', item.quantidadesaida);
        //console.log('this.valueToFloat(item.quantidade)-q: ', this.valueToFloat(item.quantidade)-qtotal);
        //console.log('qtotal: ', qtotal);
      
        if (!this.produtoUsaLote(item) && this.valueToFloat(item.quantidadesaida) > this.valueToFloat(item.quantidade)-this.valueToFloat(qtotal)) {
          itemscomerro.push(
            {
              codigo: this.trimValue(item.produto.codigo), 
              descricao: item.produto.descr,
              grade1: '',
              grade2: '',
              numerolote: '',
              msgerro: 'A quantidade de saída do produto excede a quantidade restante à ser entregue.'
            });
        }
        else if (!this.produtoUsaLote(item) && this.valueToFloat(item.quantidadesaida) > this.valueToFloat(item.quantidade)){

          console.log("item.quantidadesaida: ", item.quantidadesaida);
          console.log("item.quantidade: ", item.quantidade);

          itemscomerro.push(
            {
              codigo: this.trimValue(item.produto.codigo), 
              descricao: item.produto.descr,
              grade1: '',
              grade2: '',
              numerolote: '',
              msgerro: 'A quantidade de saída do produto excede a quantidade do produto da pré-venda.'
            });
        }
      }

      /*
      item.estoquesaida.forEach((itemestoque) => {
        itemestoque.datasaida = item.datasaida;

        if (item.produto.usagrade1 === "Não" && item.produto.usagrade2 === "Não") {
          itemestoque.quantidade = item.quantidadesaida;
          prevendaitemestoque.push(itemestoque);
        }
        else if ((this.valueToFloat(itemestoque.quantidade) > 0 ) && !(item.produto.usagrade1 === "Não" && item.produto.usagrade2 === "Não") ){
            prevendaitemestoque.push(itemestoque);
        }
      })*/
      
      CMFormGlobaisComponent.hideSpinner();
    })

    console.log('prevendaitemestoque: ', prevendaitemestoque);

    
    console.log('itemscomerro: ', itemscomerro);

    
    if (prevendaitemestoque.length <= 0){
      
      CMFormGlobaisComponent.showMessageAviso(' A quantidade de saída deve ser maior que 0. ');
    }
    else if (itemscomerro.length > 0){

      let html = "<div style='margin: 1em;'>";
      itemscomerro.forEach((item) => {
        html += `<p>
                   <span><strong>Codigo: </strong>${item.codigo} </span><br>
                   <span><strong>Produto: </strong>${item.descricao} </span><br>`;
        
        if (item.grade1 !== "" && item.grade2 === ""){
          html += `
                  <span><strong>Grade: </strong>${item.grade1} </span><br>
                `
        }
        if (item.grade2 !== "" && item.grade1 === ""){
          html += `
                  <span><strong>Grade: </strong>${item.grade2} </span><br>
                `
        }
        if (item.grade2 !== "" && item.grade1 !== ""){
          html += `
                  <span><strong>Grade: </strong>${item.grade1} / ${item.grade2} </span><br>
                `
        }

        html += `<span><strong>Erro: </strong>${item.msgerro} </span><br><br>
                 </p>
                `;
      });
      html += "</div>";
      CMFormGlobaisComponent.showMessageHtml(html, "Erro ao gerar a saída de estoque de um ou mais itens");
    }
    else{
      CMFormGlobaisComponent.showSpinner();
      
      this.postSubscribe("/api/prevenda/GerarSaidaEstoque/FuncaoExtra",
        JSON.stringify({idprevenda, estoquebaixado, prevendaitemestoque}),(ret) => {
          let produtos = [];
          if (ret["data"]) produtos = ret["data"];
          //this.filter.dados.produtos = produtos;
          this.close('formGerarSaidaEstoque')
          CMFormGlobaisComponent.hideSpinner();
          CMFormGlobaisComponent.toastSucesso("Saída gerada com sucesso.");
        },
        error => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP('gerarSaidaEstoque', error);
        }
      );
    }
  }

}
