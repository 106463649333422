// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMListCheckBoxConfig,
  CMGridEditConfigColumn,
  CMGridComponent,
  CMFormGlobaisComponent,
} from "./../../../@cmcore/component";
import { GrupoUsuarioBrowseComponent } from "./../seguranca/grupo-usuario-browse.component";
import { RecursoBrowseComponent } from "..";

@Component({
  selector: "usuario-edit",
  templateUrl: "usuario-edit.component.html",
})
export class UsuarioEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_nome";
  }

  getTitle() {
    return "Usuário";
  }

  todosRecursos = [];
  empresasUsuario = [];
  empresasSelecionadas = [];

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "usuario";
    RecursoBrowseComponent.getTodosRecursos(this.http, (dados) => {
      this.todosRecursos = dados;
    });

    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      nome: new CMInputConfig({
        form: this,
        caption: "Nome",
        required: true,
        maxlength: 255,
      }),
      ativo: new CMInputConfig({ form: this, caption: "Ativo" }),
      login: new CMInputConfig({
        form: this,
        caption: "Login",
        required: true,
        lower: true,
        minlength: 3,
        maxlength: 50,
      }),
      senha: new CMInputConfig({
        form: this,
        caption: "Senha",
        criptografado: true,
        required: true,
        minlength: 6,
        maxlength: 17,
      }),
      grupos: new CMGridEditConfig({
        form: this,
        caption: "Grupos",
        required: false,
        fields: {
          grupo: new CMInputConfig({
            form: this,
            caption: "Grupo",
            pesqOnly: true,
            required: true,
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "grupo.nome");
              },
            }),
            pesqFunc: (sender) => {
              GrupoUsuarioBrowseComponent.pesquisa(
                sender,
                this.modelConfig.grupos.current,
                "grupo"
              );
            },
          }),
        },
      }),
      empresas: new CMListCheckBoxConfig({
        form: this,
        caption: "Empresas",
        columns: 1,
        required: true,
        listFunc: () => {
          let names = []
          for (let pair of this.empresasUsuario)
            names.push(pair.name)
          return names
        },
      }),
      recursos: new CMListCheckBoxConfig({
        form: this,
        listFunc: () => {
          return this.todosRecursos;
        },
        caption: "Recursos",
        propValue: "descricao",
        subProp: "recurso",
        columns: 1,
        striping: true,
        required: false,
        onSelect: (item) => {
          if (item.id == undefined) item.id = CMGridComponent.newId();
          item.permissoes = Object.assign([], item.recurso.permissoes);
        },
        nodeList: new CMListCheckBoxConfig({
          form: this,
          columns: 1,
          showBorder: false,
          listFunc: (nodeItem, nodeValue) => {
            if (nodeItem) return nodeItem.permissoes;
            else return [];
          },
          visibleFunc: (nodeItem, nodeValue) => {
            if (
              nodeValue &&
              nodeValue.permissoes &&
              nodeValue.permissoes.length > 1
            )
              return true;
            else return false;
          },
          onGetValue: (nodeItem, nodeValue) => {
            if (nodeValue) return nodeValue.permissoes;
            else return [];
          },
          onSetValue: (nodeItem, nodeValue, value) => {
            if (nodeValue) nodeValue.permissoes = value;
          },
        }),
      }),
    };
  }

  afterGetModelData() {
    super.afterGetModelData();

    this.empresasSelecionadas = []

    if (this.model.ownerid) {
      this.model.associado_id = this.model.ownerid;
    }
    this.getEmpresasUsuario();
    console.log("model: ", this.model)
  }
  
  getEmpresasUsuario() {
    console.log("---getEmpresasUsuario---")
    CMFormGlobaisComponent.showSpinner();
    if (this.model.associado_id) {
      this.postSubscribe(
        "/api/Usuario/GetEmpresasUsuario",
        JSON.stringify({ associado: this.model.associado_id, usuario: this.model.id }),
        (result) => {
          if (result instanceof Array) {
            this.empresasUsuario.length = 0;
            Object.assign(this.empresasUsuario, result);
            
            this.empresasUsuario.forEach(object => {
              if (object.pertence === "sim" && !this.empresasSelecionadas.includes(object.name)) 
                this.empresasSelecionadas.push(object.name)
            })

            console.log("empresasSelecionadas: ", this.empresasSelecionadas)
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("getEmpresasUsuario", error);
        }
      );
    }
  }

  afterSave() {
    console.log("---after save---")
    super.afterSave();

    console.log("empresasSelecionadas: ", this.empresasSelecionadas)
    const empresasToSend = this.empresasUsuario.filter((empresa) => {
      return this.empresasSelecionadas.includes(empresa.name)
    })
    console.log("empresasToSend: ", empresasToSend)
    this.postSubscribe(
      "/api/Usuario/VinculaUsuarioEmpresas",
      JSON.stringify({ associado: this.model.associado_id, usuario: this.model.id, empresas: empresasToSend }),
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("VinculaUsuarioEmpresas", error);
      }
    );
  }
}
